import {
  mergeStyles,
  Modal,
  PrimaryButton,
  ResponsiveMode,
  Stack,
  useTheme,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import CloseModal from '../assets/close-modal.png';

export const InlineModal = (props: {
  children: React.ReactNode;
  content: string | JSX.Element;
}): JSX.Element => {
  const { children, content } = props;
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const width = Math.min(window.innerWidth, window.outerWidth) * 0.65;

  const onSubmitConsentButton = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Diasble body scroll when Modal open
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // Enable body scroll when Modal unmounts
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  return (
    <>
      <a
        style={{
          cursor: 'pointer',
          color: '#509E2F',
          textDecoration: 'underline',
          fontWeight: 'inherit',
        }}
        onClick={onSubmitConsentButton}
      >
        {children}
      </a>
      <Modal
        isOpen={isModalOpen}
        responsiveMode={ResponsiveMode.small}
        allowTouchBodyScroll={true}
        styles={{
          main: {
            borderRadius: theme.effects.roundedCorner6,
            height: 'auto',
            background: '#ffffff',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: width,
            margin: '1rem',
          },
        }}
      >
        <div
          className={mergeStyles({
            width: 'inherit',
            borderRadius: `${theme.effects.roundedCorner6} ${theme.effects.roundedCorner6} 0 0`,
            background: '#ffffff',
            overflowY: 'hidden',
          })}
        >
          (
          <>
            <img
              src={CloseModal}
              style={{
                cursor: 'pointer',
                position: 'sticky',
                float: 'right',
                width: '44px',
                height: '44px',
                padding: '0.3rem',
              }}
              onClick={() => setIsModalOpen(false)}
            />
            <br />
            <Stack horizontal horizontalAlign="center">
              <span
                style={{
                  fontFamily: 'Centra No2 Bold',
                  fontSize: '28px',
                  fontWeight: '700',
                  color: '#333',
                  paddingTop: '1rem',
                }}
              >
                XELSTRYM Copay Savings Program
              </span>
            </Stack>
          </>
          )
          <Stack
            style={{
              color: '#333',
              paddingTop: '1rem',
              maxHeight: 'fit-content',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              fontSize: '12px',
              justifyContent: 'center',
              lineHeight: '1.2',
            }}
          >
            <div
              style={{
                maxHeight: window.innerHeight / 1.55,
                paddingRight: '9%',
                paddingLeft: '9%',
              }}
            >
              {content}
            </div>
          </Stack>
          <Stack horizontalAlign="center" style={{ padding: '2rem' }}>
            <PrimaryButton
              className={mergeStyles({
                height: '3rem',
                minWidth: '120px',
                borderRadius: '4px',
                boxShadow: theme.effects.elevation8,
                border: `1px solid ${theme.palette.green}`,
                color: theme.palette.white,
                background: theme.palette.green,
                fontSize: '16px',
                fontFamily: 'Centra No2 Bold',
                fontWeight: '700',
                ':hover': {
                  background: theme.palette.green,
                  border: `1px solid ${theme.palette.green}`,
                  color: theme.palette.white,
                },
                ':active': {
                  background: theme.palette.green,
                  border: `1px solid ${theme.palette.green}`,
                  color: theme.palette.white,
                },
              })}
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </PrimaryButton>
          </Stack>
        </div>
      </Modal>
    </>
  );
};
