import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { Stack } from '@fluentui/react';

export interface NearbyPharmacy {
  name: string;
  address: string;
  city: string;
  state: string;
  phone: string;
  coordinates: {
    x: number;
    y: number;
  };
  distance: number;
  NABP: number;
  NPI: number;
}

interface GoogleMapProps {
  pharmacies: NearbyPharmacy[];
  width: string;
  height: string;
}

const GoogleMapComponent: React.FC<GoogleMapProps> = ({ pharmacies, width, height }) => {
  const [origin, setOrigin] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });

  const mapContainerStyle: React.CSSProperties = {
    width,
    height,
    color: 'black',
    border: '1px solid black',
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  useEffect(() => {
    // if (isLoaded) {
    //   setMapCenter();
    // }
    if (isLoaded && pharmacies && pharmacies.length) {
      setOrigin({ lat: pharmacies[0].coordinates.y, lng: pharmacies[0].coordinates.x });
    }
  }, [isLoaded]);

  /**
   * For setting user location as map center
   */
  // const setMapCenter = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const userLocation = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         };
  //         setOrigin(userLocation);
  //       },
  //       (error) => {
  //         console.error('Error getting user location:', error);
  //         // Handle error or provide a default location
  //         setOrigin({ lat: pharmacies[0].coordinates.x, lng: pharmacies[0].coordinates.y }); // Set origin as first pharmacy in the list for demo purposes
  //       }
  //     );
  //   } else {
  //     console.error('Geolocation is not supported by this browser.');
  //     // Handle lack of geolocation support or provide a default location
  //     setOrigin({ lat: pharmacies[0].coordinates.x, lng: pharmacies[0].coordinates.y }); // Set origin as first pharmacy in the list for demo purposes
  //   }
  // };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Stack>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={origin}
        zoom={8}
        options={{
          streetViewControl: false,
        }}
      >
        {/* Uncomment to show user location marker
         * <MarkerF position={origin} title="You are here" />
         */}
        {pharmacies.map((pharmacy, index) => (
          <MarkerF
            key={index}
            position={{ lat: pharmacy.coordinates.y, lng: pharmacy.coordinates.x }}
            // icon={'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
            title={pharmacy.name}
            onClick={() =>
              getDirections({ lat: pharmacy.coordinates.y, lng: pharmacy.coordinates.x })
            }
          />
        ))}
      </GoogleMap>
    </Stack>
  );
};

export default GoogleMapComponent;

export const getDirections = (destination: { lat: number; lng: number }) => {
  const destinationString = `${destination.lat},${destination.lng}`;
  const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destinationString}`;

  // Open Google Maps app or browser with navigation intent
  window.open(mapsUrl, '_blank');
};
