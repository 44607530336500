/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Input } from '../components/types';
import { API, CAMPAIGN_CODE, ORG_CODE } from '../config';
import { NearbyPharmacy } from '../components/AppController';

const ENROLLMENT_API_URL = `${API}/products/xelstrym/web-enroll-v2`;
const ZIPCODE_NEARBY_PHARMACY_API = `${API}/products/xelstrym/nearby-pharmacy-v2`;
const INELIGIBLE_ENROLLMENT_API_URL = `${API}/ineligible-enroll`;

interface EnrollmentResponse {
  enrollment: Enrollment;
  nearbyPharmacies: NearbyPharmacy[];
}
interface Enrollment {
  memberId: string;
  groupId: string;
  bin: string;
  pcn: string;
  ezWalletResponse: Record<any, any>;
}
interface PatientDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  zipcode: string;
  email: string;
}

const getPatientDetails = (inputs: Input[]): PatientDetails => {
  const details = new Map();
  inputs.forEach((input) => {
    details.set(input.key, input.value);
  });
  return {
    firstName: details.get('FIRST_NAME'),
    lastName: details.get('LAST_NAME'),
    phoneNumber: details.get('PHONE'),
    zipcode: details.get('ZIPCODE'),
    email: details.get('EMAIL'),
  };
};

export const createEnrollment = async (inputs: Input[]): Promise<EnrollmentResponse> => {
  try {
    const patientDetails: PatientDetails = getPatientDetails(inputs);
    const response = await axios.post(
      `${ENROLLMENT_API_URL}`,
      {
        orgCode: ORG_CODE,
        campaignCode: CAMPAIGN_CODE,
        firstName: patientDetails.firstName,
        lastName: patientDetails.lastName,
        phoneNumber: patientDetails.phoneNumber,
        zipcode: patientDetails.zipcode,
        email: patientDetails.email,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('Error', error.response?.data);
    }
    throw error;
  }
};

export const getNearbyPharamcy = async (inputs: Input[]): Promise<NearbyPharmacy[]> => {
  try {
    const patientDetails = getPatientDetails(inputs);
    const response = await axios.get(
      `${ZIPCODE_NEARBY_PHARMACY_API}?zipcode=${patientDetails.zipcode}`
    );
    return response.data as NearbyPharmacy[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('Error', error.response?.data);
    }
    throw error;
  }
};

export const createIneligibleEnrollment = async (inputs: Input[]) => {
  try {
    const patientDetails: PatientDetails = getPatientDetails(inputs);
    const response = await axios.post(
      `${INELIGIBLE_ENROLLMENT_API_URL}`,
      {
        orgCode: ORG_CODE,
        campaignCode: CAMPAIGN_CODE,
        firstName: patientDetails.firstName,
        lastName: patientDetails.lastName,
        phoneNumber: patientDetails.phoneNumber,
        zipcode: patientDetails.zipcode,
        email: patientDetails.email,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('Error', error.response?.data);
    }
    throw error;
  }
};
