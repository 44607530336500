import { PrimaryButton, Stack, mergeStyles, useTheme } from '@fluentui/react';
import React from 'react';
import XelstrymDesktopHeadingBanner from '../assets/Header-Desktop.svg';
import XelstrymMobileHeadingBanner from '../assets/background-mobile.svg';
// import TextSaveQrCode from '../assets/text-qr-code.png';
import TextSaveBubble from '../assets/text-save.png';
// import { PartnerPharmacyModal } from './PartnerPharmacyModal';
import XelstrymInfoCard from '../assets/xelstrym-static-card.png';
import { InlineModalMobile } from './InlineModalMobile';
import { InlineModal } from './InlineModal';
import { termsConditionsJSX } from '../xelstrym/Xelstrym.web';

export const HomePage = (props: { startEnrollment: () => void; isMobile: boolean }) => {
  const { startEnrollment, isMobile } = props;
  const theme = useTheme();

  if (isMobile) {
    return (
      <Stack style={{ maxWidth: '100vw' }}>
        <Stack
          horizontalAlign="center"
          verticalAlign="end"
          style={{
            minHeight: '40vh',
            maxHeight: '50vh',
            alignContent: 'center',
            textAlign: 'center',
            backgroundImage: `url(${XelstrymMobileHeadingBanner})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            maxWidth: '100vw',
            display: 'flex',
          }}
        >
          {/* <img src={XelstrymMobileHeadingBanner} style={{ width: '100%' }} /> */}
          <span
            style={{
              maxWidth: '80vw',
              textShadow: '1px 2px 2px #555',
              fontSize: '34px',
              fontWeight: '700',
              padding: '0.5rem',
              fontFamily: 'Centra No2 Bold',
              marginBottom: '2.5rem',
            }}
          >
            XELSTRYM
            <span style={{ fontSize: '17px', verticalAlign: 'sup' }}>®</span> Copay Savings Program
          </span>
        </Stack>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{
            maxWidth: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '1rem',
          }}
        >
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            style={{
              border: '1px solid #509E2F',
              width: '95%',
              borderRadius: '15px',
              background: 'radial-gradient(169.33% 99.36% at 27.01% 33.21%, #ECF6FF 0%, #FFF 100%)',
              color: '#333',
            }}
          >
            <Stack
              horizontalAlign="center"
              style={{
                width: '100%',
                background: '#509E2F',
                height: 'fit-content',
                color: '#ffffff',
                fontSize: '22px',
                fontWeight: '700',
                borderRadius: '13px 13px 0 0',
                fontFamily: 'Centra No2 Bold',
              }}
            >
              <span style={{ padding: '0.8rem' }}>Enrolling is quick and easy</span>
            </Stack>

            <Stack horizontalAlign="center" style={{ padding: '1rem' }}>
              <img src={XelstrymInfoCard} style={{ width: '100%', height: 'auto' }} />
              <p
                style={{
                  textAlign: 'center',
                  marginTop: '1rem',
                  paddingTop: '0.5rem',
                  width: '75%',
                }}
              >
                <span style={{ color: '#333', fontSize: '16px', fontWeight: '500' }}>
                  <InlineModalMobile content={termsConditionsJSX()}><b>Click here</b></InlineModalMobile>{' '}
                  for pharmacist instructions, eligibility information, and full terms and
                  conditions.
                </span>
              </p>
              <Stack style={{ width: '100%' }} horizontalAlign='start'>
                <p
                  style={{
                    color: '#333',
                    textAlign: 'start',
                    fontSize: '22px',
                    fontWeight: '700',
                    fontFamily: 'Centra No2 Bold',
                    WebkitTextStroke: '0.7px #333',
                  }}
                >
                Choose 1 of the 2 options below to begin the enrollment process.
                </p>
                <p
                  style={{
                    color: '#333',
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: 'Centra No2 Bold',
                    margin: 0,
                  }}
                >
                  Enrolling will provide you:
                </p>
                <div style={{ marginLeft: '1rem', paddingTop: '1rem' }}>
                  <ul
                    style={{
                      padding: 0,
                      margin: 0,
                      color: '#509E2F',
                      fontSize: '16px',
                      fontWeight: '400',
                      width: '100%',
                    }}
                  >
                    <li>
                      <span style={{ color: '#333' }}>
                        {/* Access to a{' '}
                      <PartnerPharmacyModal isMobile={isMobile}>
                        special partner pharmacy offer
                      </PartnerPharmacyModal>{' '}
                      that provides additional cost savings as well as potential delivery to your
                      home, free of charge. If you are not geographically eligible, this enrollment
                      also includes the non-partner pharmacy alternative, valid at all US retail
                      pharmacies */}
                      Access to copay savings card
                      </span>
                    </li>
                    <li style={{ paddingTop: '3px' }}>
                      <span style={{ color: '#333' }}>
                        {/* Valuable information and medication reminders via SMS and the ability to store
                      your cost savings offer in your phone’s digital wallet */}
                      Potential delivery to your home, free of charge (Noven C.A.N.™ Partner
                      Pharmacies only)
                      </span>
                    </li>
                    <li style={{ paddingTop: '3px' }}>
                      <span style={{ color: '#333' }}>Prescription refill reminders via SMS</span>
                    </li>
                  </ul>
                </div>
              </Stack>
              
            </Stack>
            <Stack
              horizontal
              horizontalAlign="center"
              verticalAlign="end"
              style={{
                paddingTop: '2rem',
                color: '#333',
                alignContent: 'center',
                maxWidth: '95%',
              }}
            >
              <Stack style={{ maxWidth: '50%', minWidth: '45%', alignContent: 'center' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    width: '110%',
                    borderRadius: '5px',
                    boxShadow: theme.effects.elevation8,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    border: `1px solid ${theme.palette.green}`,
                    fontFamily: 'Centra No2 Bold',
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => startEnrollment()}
                >
                  Enroll
                </PrimaryButton>
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingTop: '10px',
                  }}
                >
                  Click the <span style={{ fontFamily: 'Centra No2 Bold' }}>“Enroll”</span> <br />
                  button to begin
                </p>
              </Stack>
              <span
                style={{
                  paddingRight: '6vw',
                  paddingLeft: '6vw',
                  marginBottom: '1.5rem',
                  color: '#509E2F',
                  fontSize: '18px',
                  fontWeight: '700',
                }}
              >
                OR
              </span>
              <Stack
                style={{ maxWidth: '50%', minWidth: '40%', alignContent: 'center' }}
                verticalAlign="center"
                horizontalAlign="center"
              >
                <img src={TextSaveBubble} style={{ width: '65px', height: '80px' }} />
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingTop: '10px',
                  }}
                >
                  Text <span style={{ fontFamily: 'Centra No2 Bold' }}>“SAVE”</span> to
                  <br />
                  <a href="sms:+1-515-517-6662?&body=SAVE" style={{ textDecoration: 'underline', color: 'inherit' }}>
                  515-517-6662
                  </a>
                </p>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        style={{
          backgroundImage: `url(${XelstrymDesktopHeadingBanner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '60vh',
          alignContent: 'center',
          textAlign: 'center',
          width: '100%',
          display: 'flex',
        }}
      >
        {/* <img src={XelstrymDesktopHeadingBanner} style={{ width: '100%' }} /> */}
        <span
          style={{
            width: '80%',
            textShadow: '1px 2px 2px #555',
            fontSize: '44px',
            fontWeight: '700',
            padding: '1rem',
            maxWidth: '100vw',
            fontFamily: 'Centra No2 Bold',
          }}
        >
          XELSTRYM
          <span style={{ fontSize: '22px', verticalAlign: 'super' }}>®</span> Copay Savings Program
        </span>
      </Stack>
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        style={{
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '2rem',
        }}
      >
        <Stack
          horizontalAlign="center"
          style={{
            background: '#509E2F',
            width: '42%',
            height: 'fit-content',
            borderRadius: '0 0 15px 15px',
            padding: '1.5rem 1rem',
          }}
        >
          <div
            style={{
              fontSize: '40px',
              fontWeight: '700',
              fontFamily: 'Centra No2 Bold',
              color: '#FFFFFF',
              // padding: '0.5rem',
            }}
          >
            Enrolling is quick and easy
          </div>
        </Stack>
        <Stack horizontal style={{ width: '55%', paddingTop: '2rem' }}>
          <Stack
            style={{ width: '50%', minHeight: 'fit-content', paddingTop: '1.5rem' }}
            horizontalAlign="center"
            verticalAlign="start"
          >
            <img src={XelstrymInfoCard} style={{ width: '95%', height: 'auto' }} />
            <p
              style={{
                textAlign: 'center',
                width: '75%',
                marginTop: '3rem',
                paddingBottom: '2rem',
              }}
            >
              <span style={{ color: '#333', fontSize: '16px', fontWeight: '500' }}>
                <InlineModal content={termsConditionsJSX()}><b>Click here</b></InlineModal> for pharmacist
                instructions, eligibility information, and full terms and conditions.
              </span>
            </p>
          </Stack>
          <Stack
            style={{ width: '1%', paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
            verticalAlign="center"
          >
            <div
              style={{
                width: '2px',
                height: '85%',
                background:
                  'radial-gradient(circle, #0047BB 15%, rgba(255, 255, 255, 0) 51%) center/1px 10px',
              }}
            ></div>
          </Stack>

          <Stack style={{ width: '49%' }}>
            <p
              style={{
                color: '#333',
                textAlign: 'start',
                fontSize: '22px',
                // paddingLeft: '2rem',
                fontFamily: 'Centra No2 Bold',
                // margin: 0
                WebkitTextStroke: '0.7px #333',
              }}
            >
              Choose 1 of the 2 options below to begin the enrollment process
            </p>
            <p
              style={{
                color: '#333',
                textAlign: 'start',
                fontSize: '16px',
                // paddingLeft: '2rem',
                fontFamily: 'Centra No2 Bold',
                margin: 0,
              }}
            >
              Enrolling will provide you:
            </p>
            <ul
              style={{
                padding: 5,
                margin: '10px',
                color: '#509E2F',
                fontSize: '16px',
                fontWeight: '400',
                width: '90%',
                // paddingLeft: '2rem',
                // paddingTop: '1rem'
              }}
            >
              <li>
                <span style={{ color: '#333' }}>
                  {/* Access to a{' '}
                  <PartnerPharmacyModal isMobile={isMobile}>
                  special partner pharmacy offer
                  </PartnerPharmacyModal>{' '}
                that provides additional cost savings as well as potential delivery to your home,
                free of charge. If you are not geographically eligible, this enrollment also
                includes the non-partner pharmacy alternative, valid at all US retail pharmacies
                 */}
                  Access to copay savings card
                </span>
              </li>
              <li style={{ paddingTop: '2px' }}>
                <span style={{ color: '#333' }}>
                  {/* Valuable information and medication reminders via SMS and the ability to store your
                cost savings offer in your phone’s digital wallet */}
                  Potential delivery to your home, free of charge (Noven C.A.N.™ Partner Pharmacies
                  only)
                </span>
              </li>
              <li style={{ paddingTop: '2px' }}>
                <span style={{ color: '#333' }}>Prescription refill reminders via SMS</span>
              </li>
            </ul>
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
              style={{
                // paddingLeft: '2rem',
                paddingTop: '0.3rem',
                color: '#333',
                alignContent: 'center',
                width: '90%',
              }}
            >
              {/* <Stack style={{ width: '20%' }}>
                <img src={TextSaveQrCode} style={{ width: '124px', height: '124px' }} />
                <p style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
                  <b>Scan</b> the <br />
              QR Code
                </p>
              </Stack> */}
              {/* <span
                style={{
                  marginRight: '1.5rem',
                  marginLeft: '1.5rem',
                  color: '#509E2F',
                  fontSize: '18px',
                  fontWeight: '700',
                }}
              >
            OR
              </span> */}
              <Stack verticalAlign="center" style={{ width: '50%', paddingTop: '1rem' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    fontFamily: 'Centra No2 Bold',
                    fontSize: '18px',
                    // paddingLeft: '1rem',
                    // paddingRight: '1rem',
                    // minWidth: '55%',
                    borderRadius: '5px',
                    boxShadow: theme.effects.elevation8,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    border: `1px solid ${theme.palette.green}`,
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => startEnrollment()}
                >
                  Enroll
                </PrimaryButton>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingTop: '0.8rem',
                  }}
                >
                  Click the <span style={{ fontFamily: 'Centra No2 Bold' }}>“Enroll”</span> <br />
                  button to begin
                </p>
              </Stack>
              <span
                style={{
                  paddingRight: '1.5rem',
                  paddingLeft: '1.5rem',
                  color: '#509E2F',
                  fontSize: '18px',
                  fontWeight: '700',
                  paddingTop: '5rem',
                }}
              >
                OR
              </span>
              <Stack style={{ width: '45%' }} verticalAlign="start" horizontalAlign="center">
                <img src={TextSaveBubble} style={{ width: '50%' }} />
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingTop: '0rem',
                  }}
                >
                  Text <span style={{ fontFamily: 'Centra No2 Bold' }}>“SAVE”</span> to <br />
                  <a href="sms:+1-515-517-6662?&body=SAVE" style={{ textDecoration: 'underline', color: 'inherit' }}>
                  515-517-6662
                  </a>
                </p>
              </Stack>
              {/* <span
                style={{
                  paddingRight: '1.5rem',
                  paddingLeft: '1.5rem',
                  color: '#509E2F',
                  fontSize: '18px',
                  fontWeight: '700',
                }}
              >
            OR
              </span> */}
              {/* <Stack verticalAlign="space-around" style={{ paddingTop: '3rem' }}>
                <PrimaryButton
                  className={mergeStyles({
                    height: '3rem',
                    fontFamily: 'Centra No2 Bold',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    minWidth: '30%',
                    borderRadius: '5px',
                    boxShadow: theme.effects.elevation8,
                    color: theme.palette.white,
                    background: theme.palette.green,
                    border: `1px solid ${theme.palette.green}`,
                    ':hover': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                    ':active': {
                      background: theme.palette.green,
                      border: `1px solid ${theme.palette.green}`,
                      color: theme.palette.white,
                    },
                  })}
                  onClick={() => startEnrollment()}
                >
              Enroll
                </PrimaryButton>
                <p
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    textAlign: 'center',
                    paddingTop: '1rem',
                  }}
                >
              Click the <span style={{ fontFamily: 'Centra No2 Bold' }}>“Enroll”</span> <br />
              button to begin
                </p>
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
        {/* <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          style={{ paddingTop: '3rem', color: '#333', alignContent: 'center', maxWidth: '40%' }}
        >
          <Stack style={{ width: '20%' }}>
            <img src={TextSaveQrCode} style={{ width: '124px', height: '124px' }} />
            <p style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
              <b>Scan</b> the <br />
              QR Code
            </p>
          </Stack>
          <span
            style={{
              marginRight: '1.5rem',
              marginLeft: '1.5rem',
              color: '#509E2F',
              fontSize: '18px',
              fontWeight: '700',
            }}
          >
            OR
          </span>
          <Stack
            style={{ width: '20%', alignContent: 'center' }}
            verticalAlign="space-around"
            horizontalAlign="center"
          >
            <img src={TextSaveBubble} style={{ width: '80%' }} />
            <p
              style={{
                fontSize: '16px',
                fontWeight: '500',
                textAlign: 'center',
                paddingTop: '10px',
              }}
            >
              Text <span style={{ fontFamily: 'Centra No2 Bold' }}>“SAVE”</span> to <br />
              <u>515-517-6662</u>
            </p>
          </Stack>
          <span
            style={{
              paddingRight: '1.5rem',
              paddingLeft: '1.5rem',
              color: '#509E2F',
              fontSize: '18px',
              fontWeight: '700',
            }}
          >
            OR
          </span>
          <Stack verticalAlign="space-around" style={{ paddingTop: '3rem' }}>
            <PrimaryButton
              className={mergeStyles({
                height: '3rem',
                fontFamily: 'Centra No2 Bold',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                minWidth: '30%',
                borderRadius: '5px',
                boxShadow: theme.effects.elevation8,
                color: theme.palette.white,
                background: theme.palette.green,
                border: `1px solid ${theme.palette.green}`,
                ':hover': {
                  background: theme.palette.green,
                  border: `1px solid ${theme.palette.green}`,
                  color: theme.palette.white,
                },
                ':active': {
                  background: theme.palette.green,
                  border: `1px solid ${theme.palette.green}`,
                  color: theme.palette.white,
                },
              })}
              onClick={() => startEnrollment()}
            >
              Enroll
            </PrimaryButton>
            <p
              style={{
                fontSize: '16px',
                fontWeight: '500',
                textAlign: 'center',
                paddingTop: '1rem',
              }}
            >
              Click the <span style={{ fontFamily: 'Centra No2 Bold' }}>“Enroll”</span> <br />
              button to begin
            </p>
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  );
};
