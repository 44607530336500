export const ENV = process.env.REACT_APP_ENV;

let API_DOMAIN = '';
if (process.env.REACT_APP_ENV === 'dev') {
  API_DOMAIN = 'http://localhost:1337';
} else if (process.env.REACT_APP_ENV === 'uat') {
  API_DOMAIN = 'https://uat.api.ezchats.com';
} else if (process.env.REACT_APP_ENV === 'prod') {
  API_DOMAIN = 'https://api.ezchats.com';
}

export const API = API_DOMAIN;
export const ORG_CODE = process.env.ORG_CODE ?? 'noven';
export const CAMPAIGN_CODE = process.env.ORG_CODE ?? 'xelstrym';
